<template>
  <!-- 用户设备详情页面 -->
  <moe-page title="用户设备详情">
    <moe-describe-list title="设备信息">
      <moe-describe-item label="设备类型">{{ formatterType(equipment.type) }} </moe-describe-item>
      <moe-describe-item label="设备UID">{{ equipment.uid }} </moe-describe-item>
      <moe-describe-item label="设备型号">{{ formatterModel(equipment.type) }} </moe-describe-item>
      <moe-describe-item label="固件版本">{{ equipment.edition }}</moe-describe-item>
      <moe-describe-item label="设备名称">{{ equipment.name || formatterType(equipment.type) }} </moe-describe-item>
      <moe-describe-item label="设备图片">
        <moe-image :src="equipment.img" width="100px" height="50px" />
      </moe-describe-item>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'SmartEquipmentDetail',
  data() {
    return {
      deviceModelList: [],
      equipment: {}, //用户设备信息
    };
  },
  mounted() {
    /** 查看所有设备型号 */
    this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
      if (data.code === 200) {
        this.deviceModelList = data.result;
      }
    })
    //获取用户设备详情
    this.getequipmentDetail();
  },
  methods: {
    /**
     * 获取用户设备详情
     **/
    getequipmentDetail() {
      // 获取用户设备ID
      const deviceId = this.$route.query.id;

      // 调用用户设备API
      this.$moe_api.EQUIPMENT_API.userEquipmentDetail({ deviceId }).then(
        (data) => {
          if (data.code == 200) {
            let e = data.result;

            // 回显设备信息
            this.equipment = {
              type: e.type, //设备类型
              uid: e.uid, //设备UID
              model: e.model, //设备型号
              edition: e.edition, //固件版本
              img: e.img, //设备图片
              name: e.name, //用户名称
            };
          } else {
            this.$moe_msg.error(data.message);
          }
        }
      );
    },
    formatterType(type) {
      let findItem = this.deviceModelList.find(({ id }) => id === type)
      if (findItem) {
        return findItem.name
      }
    },
    formatterModel(type) {
      let findItem = this.deviceModelList.find(({ id }) => id === type)
      if (findItem) {
        return findItem.productModel
      }
    },
  },
};
</script>